export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135')
];

export const server_loads = [];

export const dictionary = {
		"/(platform)": [13,[4],[5]],
		"/(platform)/administration/active-users": [14,[4],[5]],
		"/(platform)/administration/auditlogs": [15,[4],[5]],
		"/(platform)/administration/db-translations": [16,[4],[5]],
		"/(platform)/administration/db-translations/create": [18,[4],[5]],
		"/(platform)/administration/db-translations/[table]/[column]/[id]": [17,[4],[5]],
		"/(platform)/administration/email-logs": [19,[4],[5]],
		"/(platform)/administration/enums/api-parameter-types": [20,[4],[5]],
		"/(platform)/administration/enums/api-parameter-types/create": [22,[4],[5]],
		"/(platform)/administration/enums/api-parameter-types/[id]": [21,[4],[5]],
		"/(platform)/administration/enums/return-reasons": [23,[4],[5]],
		"/(platform)/administration/enums/return-reasons/create": [25,[4],[5]],
		"/(platform)/administration/enums/return-reasons/[id]": [24,[4],[5]],
		"/(platform)/administration/enums/reverse-logistics-statuses": [26,[4],[5]],
		"/(platform)/administration/enums/reverse-logistics-statuses/create": [28,[4],[5]],
		"/(platform)/administration/enums/reverse-logistics-statuses/[id]": [27,[4],[5]],
		"/(platform)/administration/enums/shipper-services": [29,[4],[5]],
		"/(platform)/administration/enums/shipper-services/create": [31,[4],[5]],
		"/(platform)/administration/enums/shipper-services/[id]": [30,[4],[5]],
		"/(platform)/administration/enums/sla-tiers": [32,[4],[5]],
		"/(platform)/administration/enums/sla-tiers/create": [34,[4],[5]],
		"/(platform)/administration/enums/sla-tiers/[id]": [33,[4],[5]],
		"/(platform)/administration/enums/solution-methods": [35,[4],[5]],
		"/(platform)/administration/enums/solution-methods/create": [37,[4],[5]],
		"/(platform)/administration/enums/solution-methods/[id]": [36,[4],[5]],
		"/(platform)/administration/enums/specific-return-reasons": [38,[4],[5]],
		"/(platform)/administration/enums/specific-return-reasons/clients": [40,[4],[5]],
		"/(platform)/administration/enums/specific-return-reasons/create": [41,[4],[5]],
		"/(platform)/administration/enums/specific-return-reasons/[id]": [39,[4],[5]],
		"/(platform)/administration/logs": [42,[4],[5]],
		"/(platform)/administration/user-feedback": [43,[4],[5]],
		"/(platform)/administration/webhooks": [44,[4],[5]],
		"/(platform)/announcements": [45,[4],[5]],
		"/(platform)/announcements/create": [47,[4],[5]],
		"/(platform)/announcements/[id]": [46,[4],[5]],
		"/(platform)/bug-report": [48,[4],[5]],
		"/(platform)/chat/prompts/[id]": [49,[4],[5]],
		"/(platform)/checks": [50,[4],[5]],
		"/(platform)/clients": [51,[4],[5]],
		"/(platform)/clients/[id]": [52,[4],[5]],
		"/(platform)/collection-points/package-collections": [53,[4],[5]],
		"/(platform)/collection-points/package-collections/ordered": [55,[4],[5]],
		"/(platform)/collection-points/package-collections/sent": [56,[4],[5]],
		"/(platform)/collection-points/package-collections/transport": [57,[4],[5]],
		"/(platform)/collection-points/package-collections/[id]": [54,[4],[5]],
		"/(platform)/collection-points/package-orders": [58,[4],[5]],
		"/(platform)/collection-points/packages": [59,[4],[5]],
		"/(platform)/collection-points/packages/create": [61,[4],[5]],
		"/(platform)/collection-points/packages/create/preprocessing": [62,[4],[5]],
		"/(platform)/collection-points/packages/[id]": [60,[4],[5]],
		"/(platform)/contact-us": [63,[4],[5]],
		"/(platform)/daktela": [64,[4],[5]],
		"/(platform)/errors/not-found": [65,[4],[5]],
		"/(platform)/errors/unauthorized": [66,[4],[5]],
		"/(platform)/feature-request": [67,[4],[5]],
		"/(platform)/invoices": [68,[4],[5]],
		"/(platform)/invoices/autofill-summary": [70,[4],[5]],
		"/(platform)/invoices/costs": [71,[4],[5]],
		"/(platform)/invoices/costs/create": [72,[4],[5]],
		"/(platform)/invoices/create": [73,[4],[5]],
		"/(platform)/invoices/[id]": [69,[4],[5]],
		"/(platform)/leads": [74,[4],[5]],
		"/(platform)/leads/create": [76,[4],[5]],
		"/(platform)/leads/reminders": [77,[4],[5]],
		"/(platform)/leads/[id]": [75,[4],[5]],
		"/(login)/login": [7,[2]],
		"/(login)/password-reset": [8,[2]],
		"/(login)/password-reset/[guid]": [9,[2]],
		"/(platform)/pricing": [78,[4],[5]],
		"/(platform)/projects": [79,[4],[5]],
		"/(platform)/projects/create": [81,[4],[5]],
		"/(platform)/projects/[id]": [80,[4],[5]],
		"/(login)/registration": [10,[2]],
		"/(login)/registration/data": [11,[2]],
		"/(platform)/reports": [82,[4],[5]],
		"/(platform)/reports/clients": [83,[4],[5]],
		"/(platform)/reports/countries": [84,[4],[5]],
		"/(platform)/reports/invoice/[code]": [85,[4],[5]],
		"/(platform)/returns-management": [86,[4],[5]],
		"/(platform)/returns-management/configuration": [88,[4],[5]],
		"/(platform)/returns-management/configuration/[code]/emails": [89,[4],[5]],
		"/(platform)/returns-management/configuration/[code]/emails/[typeId]": [90,[4],[5]],
		"/(platform)/returns-management/configuration/[code]/form": [91,[4],[5]],
		"/(platform)/returns-management/configuration/[code]/integration": [92,[4],[5]],
		"/(platform)/returns-management/configuration/[code]/process": [93,[4],[5]],
		"/(platform)/returns-management/[code]": [87,[4],[5]],
		"/(platform)/shopify/auth/install": [94,[4],[5]],
		"/(platform)/shopify/auth/login": [95,[4],[5]],
		"/(platform)/shopify/auth/result": [96,[4],[5]],
		"/(platform)/shopify/orders": [97,[4],[5]],
		"/(platform)/support/dashboard": [98,[4],[5]],
		"/(platform)/support/other-communications": [99,[4],[5]],
		"/(platform)/support/report": [100,[4],[5]],
		"/(platform)/support/report/activities": [102,[4],[5]],
		"/(public)/support/report/public/[code]": [135,[6]],
		"/(platform)/support/report/themes": [103,[4],[5]],
		"/(platform)/support/report/[id]/[year]-[month]": [101,[4],[5]],
		"/(platform)/tasks": [104,[4],[5]],
		"/(platform)/tasks/create": [106,[4],[5]],
		"/(platform)/tasks/[id]": [105,[4],[5]],
		"/(platform)/timer": [107,[4],[5]],
		"/(platform)/timer/report": [108,[4],[5]],
		"/(platform)/tools": [109,[4],[5]],
		"/(platform)/tools/exchange-rates": [110,[4],[5]],
		"/(platform)/tools/migration": [111,[4],[5]],
		"/(platform)/tools/notification-settings": [112,[4],[5]],
		"/(platform)/translations": [113,[4],[5]],
		"/(platform)/translations/create": [115,[4],[5]],
		"/(platform)/translations/reports": [116,[4],[5]],
		"/(platform)/translations/translators": [117,[4],[5]],
		"/(platform)/translations/translators/[id]": [118,[4],[5]],
		"/(platform)/translations/[id]": [114,[4],[5]],
		"/(noauth)/ui/modal-dropdown": [12,[3]],
		"/(platform)/users": [119,[4],[5]],
		"/(platform)/users/create": [128,[4],[5]],
		"/(platform)/users/my-profile": [129,[4],[5]],
		"/(platform)/users/my-profile/api-settings": [130,[4],[5]],
		"/(platform)/users/my-profile/customorders": [131,[4],[5]],
		"/(platform)/users/my-profile/feedback": [132,[4],[5]],
		"/(platform)/users/my-profile/notifications": [133,[4],[5]],
		"/(platform)/users/my-profile/webhooks": [134,[4],[5]],
		"/(platform)/users/[id]": [120,[4],[5]],
		"/(platform)/users/[id]/customorders": [121,[4],[5]],
		"/(platform)/users/[id]/integration": [122,[4],[5]],
		"/(platform)/users/[id]/notifications": [123,[4],[5]],
		"/(platform)/users/[id]/pricing": [124,[4],[5]],
		"/(platform)/users/[id]/service-provider-pricing": [125,[4],[5]],
		"/(platform)/users/[id]/translator-pricing": [126,[4],[5]],
		"/(platform)/users/[id]/webhooks": [127,[4],[5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';